import React, { FC, useState, useEffect, useContext, useCallback } from "react";
import { UserManager, User, Profile, Log } from "oidc-client";
import { useHistory, useLocation } from "react-router";

import { AuthContextProps } from "./AuthContextInterface";
import jwt_decode from "jwt-decode";
import { globalAxiosClient } from "modules/Shared/api/client";

export const AuthContext = React.createContext<AuthContextProps | null>(null);

export const routeIsOIDCCodeCallback = (): boolean => {
  const searchParams = new URLSearchParams(window.location.search);
  const hashParams = new URLSearchParams(
    window.location.hash.replace("#", "?")
  );
  return Boolean(
    window.location.pathname.toLowerCase().startsWith(`/callback`) &&
    (searchParams.get("code") ||
      searchParams.get("id_token") ||
      searchParams.get("session_state") ||
      hashParams.get("code") ||
      hashParams.get("id_token") ||
      hashParams.get("session_state"))
  );
};

/**
 * @private
 * @hidden
 * @param props
 */

export const useAuthContext = () => {
  return useContext<AuthContextProps | null>(AuthContext);
};

/**
 *
 * @param props AuthProviderProps
 */
export const AuthProvider: FC = ({ children, ...props }) => {
  Log.logger = console;
  Log.level = Log.WARN;

  const initUserManager = (): UserManager => {
    const userManager = new UserManager({
      authority: process.env.REACT_APP_AUTH_STS_AUTHORITY,
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      redirect_uri: `${process.env.REACT_APP_AUTH_CLIENT_ROOT}/callback`,
      silent_redirect_uri: `${process.env.REACT_APP_AUTH_CLIENT_ROOT}/silentrenew`,
      post_logout_redirect_uri: `${process.env.REACT_APP_AUTH_CLIENT_ROOT}/logout`,
      response_type: "code",
      scope: process.env.REACT_APP_AUTH_CLIENT_SCOPE,

      includeIdTokenInSilentRenew: true,
      loadUserInfo: true,
      automaticSilentRenew: true,
      metadata: {
        issuer: process.env.REACT_APP_AUTH_STS_AUTHORITY,
        jwks_uri:
          process.env.REACT_APP_AUTH_STS_AUTHORITY +
          "/.well-known/openid-configuration/jwks",
        authorization_endpoint:
          process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/authorize",
        token_endpoint:
          process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/token",
        userinfo_endpoint:
          process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/userinfo",
        end_session_endpoint:
          process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/endsession",
        check_session_iframe:
          process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/checksession",
        revocation_endpoint:
          process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/revocation",
        introspection_endpoint:
          process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/introspect",
      },
    });

    userManager.events.addSilentRenewError((error: Error) => {
      window.analytics?.trackEvent(window.analytics.events.AuthSilentRenewError, { error: error });
      console.log(error);
    });

    userManager.events.addUserLoaded(function (user) {
      userManager.getUser().then(function () { });
    });

    userManager.events.addUserUnloaded(() => { });

    userManager.events.addUserSessionChanged(() => { });

    return userManager;
  };

  const [user, setUser] = useState<User | null>(null);

  // const [isAuthenticated, setisAuthenticated] = useState<Boolean>(false);

  const [logoutStarted, setlogoutStarted] = useState<Boolean>(false);

  const [userManager, setUserManager] = useState<UserManager | null>(null);

  function getUserManagerLazy() {
    const um = userManager;
    if (userManager === null) {
      const um = initUserManager();
      setUserManager(um);
    }

    return um;
  }

  const location = useLocation();
  const history = useHistory();

  // useEffect(() => {
  //   if (userData && routeIsOIDCCodeCallback()) {
  //     history.replace({ pathname: "/", search: "" });
  //   }
  // }, [userData]);

  useEffect(() => {
    const userManager = getUserManagerLazy();
    const getUser = async (): Promise<void> => {
      /**
       * Check if the flow is returning back from OIDC callback. If so, it should take the query params and obtin id token, access token and user profile and store them in session storage
       */
      if (routeIsOIDCCodeCallback()) {

        window.analytics?.trackEvent(window.analytics.events.AuthCallback);

        await userManager?.signinRedirectCallback().then((user) => {
          userManager?.getUser().then(() => {
            user && setUser(user);

            window.analytics?.trackEvent(window.analytics.events.AuthCallbackSuccess);

            history.replace({ pathname: user.state.url, search: "" });

          });
        }).catch((error) => {
          //this should not happen, it can happen in rare cases (for example, local state has been manually deleted before login callback ? or callback link pasted in incognito window)
          //Either way, we can redirect to dashboard, and since route is protected, it will redirect to login
          //If this error is found in analytics, it should be investigated
          window.analytics?.trackEvent(window.analytics.events.AuthCallbackFailed, { error: error });
          history.replace({ pathname: "/dashboard" });
        });
        return;
      }

      const autoSignIn = false;
      const user = await userManager?.getUser();
      if ((!user || user.expired) && autoSignIn) {
        signIn();
      } else {
        //user && setUser(user);
      }
      return;
    };

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location, userManager]);

  const signIn = useCallback(async (username?: string, redirectUrl?: string): Promise<void> => {
    if (logoutStarted) {
      return;
    }
    await userManager?.signinRedirect({
      state: { url: redirectUrl ? redirectUrl : window.location.pathname },
      login_hint: username ?? ""
    });
  }, [userManager, logoutStarted]);

  const signOut = useCallback(async (): Promise<void> => {
    setlogoutStarted(true);
    await userManager?.signoutRedirect();
  }, [userManager]);

  const autoLoginOverrideAfterRegistration = useCallback(
    async (
      refresh_token: string,
      access_token: string,
      id_token: string,
      successCallback?: (() => any) | undefined | null,
      failCallback?: (() => any) | undefined | null
    ): Promise<void> => {
      const decoded_access_token: any = jwt_decode(access_token);
      const decoded_id_token: any = jwt_decode(id_token);

      let profile: Profile;
      profile = {
        iss: decoded_id_token.iss,
        aud: decoded_id_token.aud,
        exp: decoded_id_token.exp,
        sub: decoded_id_token.sub,
        iat: decoded_id_token.iat,
        auth_time: decoded_id_token.auth_time,
      };

      const user = new User({
        access_token: access_token,
        expires_at: decoded_access_token.exp,
        id_token: id_token ?? "",
        profile: profile,
        refresh_token: refresh_token,
        token_type: "Bearer",
        scope: process.env.REACT_APP_AUTH_CLIENT_SCOPE!,
        session_state: "",
        state: "",
      });

      await userManager?.storeUser(user).then((fulfilledObj: any) => {
        userManager?.getUser().then((user: User | null) => {
          window.analytics?.trackEvent(window.analytics.events.AuthTokenOverriden);
          user && setUser(user);
          successCallback && successCallback();
        });
      }, (rejectedObj: any) => {
        //onrejected
        failCallback && failCallback();
      });
    },
    [userManager]
  );

  const getAccessToken = useCallback(async (): Promise<string | undefined> => {
    const user = await getUserManagerLazy()?.getUser();
    if (!user) {
      window.analytics?.trackEvent(window.analytics.events.AuthAccessTokenNotFound);//this event might not be tracked because signin will redirect to identity server
      await signIn();
      return undefined;
    } else {
      return user!.access_token;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userManager]);

  globalAxiosClient.interceptors.response.use(res => res, async (error) => {
    if (error?.response?.status === 401) {
      await signIn();
    }
    return Promise.reject(error);
  });

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        autoLoginOverrideAfterRegistration,
        getAccessToken,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
